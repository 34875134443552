<template>
  <div>
    <div v-if="!isLoading">
      <TopMenuSubheadline :title="title" />
      <div class="app-content-box bg-gray profile-reviews">
        <div class="container reviews-container" v-if="reviews.length">
          <div class="review-box" v-for="(review, key) in reviews" :key="key">
            <div class="review-box-inner">
              <div>
                <div class="review-cover">
                  <a href="#" class="">
                    <img :src="(coverUrl + review.image)" :alt="review.tytul" />
                  </a>
                </div>
                <div class="review-content" :class='{expanded: isReadMore}'>
                  <div class="review-title">
                    <span class="title-link">{{review.tytul}}</span>
                    <br>
                  </div>
                  <p>
                    {{review.content.slice(0, 150)}}
                  </p>
                <div class="btn-cotainer">
                  <button class="btn-black-border mt-3 mb-1" @click="routerMore(review.id, review.book_id)">zobacz</button>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container reviews-container-empty" v-else>
          <div class="text-center">Użytkownik nie ma żadnych recenzji</div>
        </div>
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import Loader from '../components/Loader'
// import ReviewElement from '../components/ReviewElement'
export default {
  name: 'ReviewOtherUser',
  components: {
    TopMenuSubheadline,
    Loader
    // ReviewElement
  },
  props: {
    user_id: [String, Number]
  },
  data () {
    return {
      title: 'recenzje',
      isLoading: true,
      reviews: [],
      isReadMore: false,
      my_id: ''
    }
  },
  async created () {
    await this.$https('/profile', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        this.my_id = data.id
        if (parseInt(this.user_id, 10) === parseInt(this.my_id, 10)) {
          this.$router.push('/recenzje')
        }
      })
      .catch(error => {
        console.log('', error)
      })
    await this.$https('/profile/review/' + this.user_id, {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        this.reviews = data.response
        this.isLoading = false
      })
      .catch(error => {
        console.log('', error)
      })
  },
  methods: {
    routerMore (revId, bookId) {
      this.$router.push({ name: 'ReviewDetails', params: { id: revId, book_id: bookId } })
    }
  }
}
</script>
<style lang="scss" scoped>
.app-content-box {
  min-height: 100vh;
}
.reviews-container {
  padding-top: 50px;
  margin-bottom: 40px;

  &-empty {
    display: flex;
    height: 90vh;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    line-height: 30px;
  }
}
.btn-cotainer {
  width: 100%;
}
</style>
